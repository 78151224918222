<script setup lang="ts">
const appConfig = useAppConfig()
const { toggleContentSearch } = useUIState()
const { metaSymbol } = useShortcuts()
</script>

<template>
  <UiButton
    variant="outline"
    :icon="appConfig.ui.icons.search"
    truncate
    aria-label="Search"
    size="sm"
    class="w-[200px] py-2"
    @click="toggleContentSearch"
  >
    <template #trailing>
      <div class="hidden lg:flex items-center gap-0.5 ml-auto -my-1 flex-shrink-0">
        <UKbd>
          {{ metaSymbol }}
        </UKbd>
        <UKbd>
          K
        </UKbd>
      </div>
    </template>
    <div class="font-normal">
      Search on Forgd
    </div>
  </UiButton>
</template>
